import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';

interface AppConfig {
    colorScheme: string;
    theme: string;
    menuMode: string;
}

@Injectable()
export class LayoutService {
    theme: string = 'aura-dark-indigo';
    colorScheme: string = 'light';
    checked: boolean = this.colorScheme === 'dark'; // checked = true -> dark mode | checked = false -> light mode

    private readonly platformId = inject(PLATFORM_ID);

    config: AppConfig = {
        menuMode: 'static',
        colorScheme: this.colorScheme,
        theme: this.theme,
    };

    constructor() {
        this.theme = 'aura-dark-indigo';
        this.colorScheme = 'light';

        this.checkThemeModeSaved();
    }

    checkThemeModeSaved() {
        this.setThemeMode(this.theme, this.colorScheme);
    }

    changeTheme(event: any) {
        const theme = event.checked ? 'aura-dark-indigo' : 'aura-dark-indigo';
        const colorScheme = event.checked ? 'light' : 'dark';

        this.setThemeMode(theme, colorScheme);
    }

    setThemeMode(theme: string, colorScheme: string) {
        if (isPlatformBrowser(this.platformId)) {
            const themeLink = document.getElementById('theme-css') as HTMLLinkElement;

            if (!themeLink) {
                console.log('Theme link element not found');
                return;
            }

            let themeChange: string = '';
            const currentTheme = this.config.theme;

            if (theme === currentTheme) {
                if (theme === 'aura-dark-indigo') {
                    themeChange = 'aura-dark-indigo';
                }
                if (theme === 'aura-dark-indigo') {
                    themeChange = 'aura-dark-indigo';
                }
            } else {
                themeChange = currentTheme;
            }

            const newHref = themeLink.href.replace(themeChange, theme);

            this.replaceThemeLink(newHref, () => {
                this.config.theme = this.theme = theme;
                this.config.colorScheme = this.colorScheme = colorScheme;
                this.checked = colorScheme === 'light';
            });
        }
    }

    replaceThemeLink(href: string, onComplete: () => void) {
        const themeLink = document.getElementById('theme-css') as HTMLLinkElement;

        if (!themeLink) {
            console.log('Theme link element not found');
            return;
        }

        const cloneLinkElement = themeLink.cloneNode(true) as HTMLLinkElement;

        cloneLinkElement.href = href;
        cloneLinkElement.id = 'theme-css-clone';

        themeLink.insertAdjacentElement('afterend', cloneLinkElement);

        cloneLinkElement.addEventListener('load', () => {
            themeLink.remove();
            cloneLinkElement.id = 'theme-css';
            onComplete();
        });

        cloneLinkElement.addEventListener('error', (e) => {
            console.log('Error loading new theme', e);
        });
    }
}
